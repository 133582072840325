/* 04.8 Icon Box CSS */

.my-steps {

    // @media (min-width:1025px) {
    //     margin-left: 80px;
    // }

    @media (min-width:1281px) {
        margin-left: 80px;
    }
}

.steps-container {
    display: flex;
    flex-direction: column;
    align-items: center; // Center-align items horizontally
}

.step {
    display: flex;
    flex-direction: column; // Stack items vertically
    align-items: center; // Center-align items horizontally
    margin-bottom: 20px;
}

.step-number {
    width: 50px;
    height: 50px;
    background-color: #1292ee;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin-bottom: 10px; // Adjust margin to create space between number and content
}

.step-content {
    text-align: center; // Center-align the content
}


.my-icon-box {


    position: relative;
    display: block;
    height: 100%;

    transition: var(--transition);
    border-radius: 5px;
    z-index: 99;
    background-color: var(--clr-white);


    @media (min-width:1025px) {
        padding: 100px 35px;
        width: min-content;
    }

    @media (min-width:1281px) {
        padding: 100px 35px;
        width: min-content;
    }

    @media (min-width:961px) {
        padding: 55px 35px;
        width: min-content;
    }

    @media (max-width: 991px) {}

    @media #{$tablet-device} {
        padding: 30px 35px;
    }

    @media #{$large-mobile} {
        padding: 20px 25px;
    }

    & .icon {
        margin-bottom: 35px;

        @media #{$desktop-device} {
            margin-bottom: 20px;
        }

        @media #{$large-mobile} {
            margin-bottom: 20px;
        }

        & svg {
            width: 50px;
            height: 50px;

            & path {
                stroke: var(--clr-primary);
            }
        }
    }

}

.icon-box-two {

    position: relative;
    display: block;
    height: 100%;
    padding: 50px 60px;

    transition: var(--transition);
    border-radius: 5px;
    z-index: 99;
    background-color: var(--clr-white);

    &.box-border {
        border: 1px solid #007bff6b;
    }

    // Responsive
    @media #{$desktop-device} {
        padding: 30px 35px;
    }

    @media #{$tablet-device} {
        padding: 30px 35px;
    }

    @media #{$large-mobile} {
        padding: 20px 25px;
    }

    & .icon {
        margin-bottom: 35px;

        @media #{$desktop-device} {
            margin-bottom: 20px;
        }

        @media #{$large-mobile} {
            margin-bottom: 20px;
        }

        & svg {
            width: 50px;
            height: 50px;

            & path {
                stroke: var(--clr-primary);
            }
        }
    }

    & .content {
        & .title {
            font-size: 20px;
            line-height: 1;

            margin: 0;

            color: var(--clr-heading);
        }

        & .desc {
            margin-top: 25px;

            // Responsive
            @media #{$desktop-device} {
                margin-top: 20px;
            }

            @media #{$large-mobile} {
                margin-top: 20px;
            }

            & p {
                line-height: 1.6;

                color: var(--clr-body);
            }
        }

    }

}

.icon-box {
    position: relative;
    display: block;
    height: 100%;
    padding: 50px 60px;

    transition: var(--transition);
    border-radius: 5px;
    z-index: 99;
    background-color: var(--clr-white);

    &.box-border {
        border: 1px solid #e7e7e7;

        &:hover {
            border-color: var(--clr-white);
            box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
        }
    }

    // Responsive
    @media #{$desktop-device} {
        padding: 30px 35px;
    }

    @media #{$tablet-device} {
        padding: 30px 35px;
    }

    @media #{$large-mobile} {
        padding: 20px 25px;
    }

    & .icon {
        margin-bottom: 35px;

        @media #{$desktop-device} {
            margin-bottom: 20px;
        }

        @media #{$large-mobile} {
            margin-bottom: 20px;
        }

        & svg {
            width: 50px;
            height: 50px;

            & path {
                stroke: var(--clr-primary);
            }
        }
    }

    & .content {
        & .title {
            font-size: 20px;
            line-height: 1;

            margin: 0;

            color: var(--clr-heading);
        }

        & .desc {
            margin-top: 25px;

            // Responsive
            @media #{$desktop-device} {
                margin-top: 20px;
            }

            @media #{$large-mobile} {
                margin-top: 20px;
            }

            & p {
                line-height: 1.6;

                color: var(--clr-body);
            }
        }

        & .link {
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            display: inline-flex;
            align-items: center;
            margin-top: 35px;
            color: var(--clr-primary);

            // Responsive
            @media #{$desktop-device} {
                margin-top: 20px;
            }

            @media #{$large-mobile} {
                margin-top: 20px;
            }
        }
    }

    &:hover {
        box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
        transform: translateY(-5px);
        z-index: 1;




        & .content {
            & .link {
                color: var(--clr-primary);
            }
        }
    }
}

.icon-box-shape-animation {
    position: relative;

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            top: -40px;
            left: -50px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$laptop-device} {
        & .shape {

            &-1 {
                top: -40px;
                left: 0px;
                z-index: -1;
            }
        }
    }

    @media #{$desktop-device} {
        & .shape {

            &-1 {
                top: -20px;
                left: 0px;
                z-index: -1;
            }
        }
    }

    @media #{$tablet-device} {
        & .shape {

            &-1 {
                top: -20px;
                left: 0px;
                z-index: -1;
            }
        }
    }

    @media #{$large-mobile} {
        & .shape {

            &-1 {
                top: -20px;
                left: 0px;
                z-index: -1;
            }
        }
    }
}